import React, { useContext, useMemo } from "react";
import Styles from "./MarketplaceLorryCard.module.scss";
import { LorryMarketCard } from "../../../services/lorry/useGetLorryMarketV2/type";
import Lorry from "../lorry/Lorry";
import LineDivider from "@vahak/core-ui/dist/components/LineDivider";
import MarketplaceLoadLorryQuickInfo from "../marketplace-load-lorry-quick-info/MarketplaceLoadLorryQuickInfo";
import { getLorryDetailsByType } from "@vahak/core/dist/constants";
import UserProfileInfo from "../user-profile-info/UserProfileInfo";
import Typography from "@vahak/core-ui/dist/components/Typography";
import Button from "@vahak/core-ui/dist/components/Button";
import COLORS from "@vahak/core-ui/dist/constants/colors";
import { getPriceString } from "@vahak/core-ui/dist/methods/common";
import { milliseconds } from "@vahak/core-ui/dist/methods/time";
import Flex, { VerticalFlex } from "@vahak/core-ui/dist/layout/Flex";
import CurrentLocationIcon from "@vahak/core/dist/icons/location-grey.svg";
import PriceIcon from "@vahak/core/dist/standard-icons/payment-coin.svg";
import ShareIcon from "@vahak/core/dist/standard-icons/shareIcon.svg";
import { AppContext } from "@vahak/core/dist/app-context";
import IfCanAccess from "../../admin/IfCanAccess/IfCanAccess";
import AgentBadge from "../../admin/badge/AgentBadge";
import IconWrapper from "@vahak/core-ui/dist/components/IconWrapper";
import { useCsDashboard } from "@vahak/core/dist/hooks/useCsDashboard";
import { ADMIN_OPERATION } from "../../../constants/adminOperation";
import { useGetContactDetails } from "../../../hooks/useGetContactDetails";
import { C2C_REFERENCE_TYPE } from "../../../constants/c2c-reference-type";
import { GenericObject } from "@vahak/core-ui/dist/types";
import { stringOperations } from "@vahak/core-ui/dist/methods";
import { useEventTrackerService } from "@vahak/core/dist/_services/hooks/useEventTrackerService";
import CallCircle from "@vahak/core/dist/icons/call-circle.svg";
import Dropdown from "@vahak/core/dist/components/dropdown/Dropdown";
import { useToggleModalWithLocalState } from "@vahak/core-ui/dist/hooks";
import DotsMoreIcon from "@vahak/core/dist/standard-icons/options.svg";
import useUpdateDisposition from "../../../hooks/useUpdateDisposition";
import { UpdateRemarksPayload } from "../../../services/remarks/useUpdateRemarks";
import CallingDispositionView from "../../admin/calling-disposition-view/CallingDispositionView";
import queryNames from "../../../services/queryNames";
import classnames from "classnames";

export function getRelativeTimeString(date: Date | number) {
    let timeUnits = {
        year: milliseconds(365),
        day: milliseconds(1)
    } as Record<string, number>;

    const timeMs = typeof date === "number" ? date : date.getTime();
    if (isNaN(timeMs)) {
        return "";
    }
    const delta = timeMs - Date.now();
    const requiredUnit =
        Object.keys(timeUnits).find((key) => {
            return Math.abs(delta) > timeUnits[key];
        }) ?? "day";

    const result = Math.floor(delta / timeUnits[requiredUnit]);

    const rtf = new Intl.RelativeTimeFormat("en-IN");
    return rtf.format(result, requiredUnit as any);
}

interface MarketplaceLorryCardProps {
    data: LorryMarketCard;
    id?: string;
    position?: number;
    c2cReferenceId?: number;
    onBidNowClick: () => void;
    handleClickProfile?: () => void;
    trackOnClickBidNow?: () => void;
    remarksPayload?: Partial<UpdateRemarksPayload>;
    evenSourceScreen?: string;
    mobileResponsive?: boolean;
}

const MarketplaceLorryCard = ({
    data,
    id = "",
    onBidNowClick,
    trackOnClickBidNow,
    handleClickProfile,
    remarksPayload,
    evenSourceScreen,
    mobileResponsive = true
}: MarketplaceLorryCardProps) => {
    const { setShowShareModalPopup, companyId } = useContext(AppContext);
    const { checkPermission } = useCsDashboard();

    const {
        isModalOpen: isAdminOptionMenuOpen,
        closeModal: closeAdminOptionMenu,
        toggleModal: toggleAdminOptionMenu
    } = useToggleModalWithLocalState(false);

    const { modalSlot: dispositionModalSlot, toggleModal: toggleDispositionModal } = useUpdateDisposition({
        type: "bid",
        payload: remarksPayload,
        successQueries: [queryNames.getLiveLorriesByLoadIdInfiniteQueryV2],
        successMsg: "Updated!"
    });

    const { company_detail, lorry_detail, agent_detail } = data ?? {};
    const selectedLorryConfig = getLorryDetailsByType(lorry_detail?.type_value);
    const timeAgo = useMemo(
        () => !!lorry_detail?.last_activated_on && getRelativeTimeString(lorry_detail?.last_activated_on * 1000),
        [lorry_detail?.last_activated_on]
    );

    const toggleShareModal = () => {
        setShowShareModalPopup?.({
            isVisible: true,
            mktType: "lorry",
            data: {
                id: lorry_detail?.id,
                source: lorry_detail?.source_info?.city_name,
                capacity: lorry_detail?.capacity,
                capacity_unit: selectedLorryConfig?.unit,
                name: selectedLorryConfig?.label,
                lorry_type_id: selectedLorryConfig?.id,
                transporter_name: company_detail?.name,
                current_city_name: lorry_detail?.current_city,
                oppositeCompanyId: company_detail?.id,
                company_service_type: company_detail?.service_type
            }
        });
    };

    const { sendGAandMoEngageEvent } = useEventTrackerService();

    const handleTracking = (name: string, additionalData: GenericObject = {}) => {
        const lorryReq = stringOperations(data.lorry_detail.lorry_type ?? "", [
            "replaceSpacesWithUnderscore",
            "toLowerCase"
        ]);

        const eventData = {
            source_screen: evenSourceScreen || "tso_lorry_market",
            tso_tagged: !!data.agent_detail?.agent_name,
            ...(!!data.agent_detail?.agent_name && {
                tagged_tso_id: data.agent_detail?.user_id
            }),
            ...additionalData,
            from_location: data.lorry_detail.source_text,
            to_location: data.lorry_detail.total_routes,
            required_vehicle_type: lorryReq,
            lorry_id: data.lorry_detail.id,
            is_lorry_verified: data.lorry_detail.is_verified,
            vehicle_number: data.lorry_detail.number,
            lorry_type: lorryReq,
            lorry_capacity: data.lorry_detail.capacity
        };

        sendGAandMoEngageEvent({
            name,
            data: eventData
        });
    };

    const { connectC2C } = useGetContactDetails(data.company_detail?.id?.toString()!);

    const _onClickGetContactTso = () => {
        /**
         * Use C2C_REFERENCE_TYPE.LOAD_MARKETPLACE once added in backend
         */
        connectC2C({
            callerId: Number(companyId),
            calleeId: Number(data.company_detail?.id?.toString()),
            referenceId: Number(data.lorry_detail.id),
            referenceType: C2C_REFERENCE_TYPE.TAGGED_USER_LOAD_BID
        });
        handleTracking?.("call_initiated");
    };

    const AgentDetailsSlot = <AgentBadge type="tso" data={agent_detail} />;

    const C2cBtnSlot = (
        <>
            {data.agent_detail?.phone_number && (
                <IfCanAccess action={ADMIN_OPERATION.tagAgent} alsoCheck={!!data.company_detail?.id}>
                    <span className={Styles.callBtn} onClick={_onClickGetContactTso}>
                        <IconWrapper width={32} height={32}>
                            <CallCircle />
                        </IconWrapper>
                    </span>
                </IfCanAccess>
            )}
        </>
    );

    const DropdownSlot = (
        <>
            <IfCanAccess action={ADMIN_OPERATION.userLoadLorryAction}>
                <>
                    {dispositionModalSlot}
                    <Dropdown
                        isOpen={isAdminOptionMenuOpen}
                        DropdownToggleBtn={
                            <div className={Styles.langIconWrapper} style={{ cursor: "pointer" }}>
                                <DotsMoreIcon />
                            </div>
                        }
                        handleToggle={toggleAdminOptionMenu}
                        dropdownClassName={Styles.adminOptionDropdown}
                        dropdownToggleClassName={Styles.adminOptionDropdownToggle}
                    >
                        <Button
                            blockBtn
                            fillType="text"
                            size="s"
                            onClick={() => {
                                closeAdminOptionMenu();
                                toggleDispositionModal();
                            }}
                        >
                            Update Disposition
                        </Button>
                        <Button
                            blockBtn
                            fillType="text"
                            size="s"
                            onClick={() => {
                                closeAdminOptionMenu();
                                toggleShareModal();
                            }}
                        >
                            Share
                        </Button>
                    </Dropdown>
                </>
            </IfCanAccess>
        </>
    );
    return (
        <div className={classnames(Styles.main, mobileResponsive && Styles.mobileResponsive)} id={`${id}-card`}>
            <div className={Styles.lorryWrapper}>
                {!!selectedLorryConfig?.id && (
                    <Lorry
                        bodyType={lorry_detail?.body_type}
                        lorryType={selectedLorryConfig?.id}
                        id={id}
                        isVerified={lorry_detail?.is_verified}
                        lorryId={lorry_detail?.id}
                        lorryModel={lorry_detail?.lorry_model}
                        lorryNumber={lorry_detail?.number}
                    />
                )}
                {!!remarksPayload && checkPermission(ADMIN_OPERATION.userLoadLorryAction) ? (
                    DropdownSlot
                ) : (
                    <Button fillType="text" onClick={toggleShareModal} id={`${id}--share`}>
                        <Typography color={COLORS.GREY_400} size="xs" noWrap>
                            <ShareIcon /> Share
                        </Typography>
                    </Button>
                )}
            </div>
            <div className={Styles.bodyContent}>
                <LineDivider styleType="solid" linearGradient={true} />

                <Typography weight="semibold">
                    <div className={Styles.lorryOtherInfo}>
                        <VerticalFlex gap={8}>
                            <Flex alignItems="center" gap={4}>
                                <IconWrapper svgFillColor={COLORS.GREEN_600}>
                                    <CurrentLocationIcon />
                                </IconWrapper>
                                <Typography color={COLORS.GREY_500} size="xs">
                                    Current Location:
                                </Typography>
                            </Flex>
                            <Typography color={COLORS.GREY_800} size="s" id={`${id}-lorry-current-location`}>
                                {lorry_detail?.current_city}
                            </Typography>

                            <Typography color={COLORS.GREY_500} size="xs">
                                Last updated :{" "}
                                <Typography color={COLORS.GREY_700} textTransform="capitalize">
                                    {timeAgo}
                                </Typography>
                            </Typography>
                        </VerticalFlex>
                        {lorry_detail?.estimated_bidding_amount > 0 && (
                            <>
                                <div className={Styles.divider}></div>
                                <VerticalFlex justifyContent="center" gap={10}>
                                    <Flex alignItems="center" gap={4}>
                                        <PriceIcon />
                                        <Typography color={COLORS.GREY_500} size="xs">
                                            Estimated price :{" "}
                                        </Typography>
                                    </Flex>
                                    <Typography color={COLORS.GREY_700} size="xs">
                                        <Typography id={`${id}-lorry-price`}>
                                            {getPriceString(lorry_detail?.estimated_bidding_amount)}
                                        </Typography>
                                        <Typography color={COLORS.GREY_500}> / tonne-km</Typography>
                                    </Typography>
                                </VerticalFlex>
                            </>
                        )}
                    </div>
                </Typography>

                <LineDivider styleType="solid" linearGradient={true} />

                <MarketplaceLoadLorryQuickInfo
                    id={id}
                    lorryType={selectedLorryConfig?.id}
                    capacity={lorry_detail?.capacity}
                    tyres={lorry_detail?.total_tyres}
                    type="lorry"
                    fontWeight="medium"
                    lorrySize={lorry_detail?.size}
                    lorrySizeText={lorry_detail?.size_text}
                    showLorryLabel={false}
                    fuelType={lorry_detail?.fuel_type}
                />

                <IfCanAccess action={""} allowCsUser>
                    <LineDivider styleType="solid" linearGradient={true} margin="10px 0" />
                    <Flex justifyContent="space-between" alignItems="center" style={{ height: "38px" }}>
                        {AgentDetailsSlot}
                        {C2cBtnSlot}
                    </Flex>
                    <IfCanAccess action={""} alsoCheck={!!data?.lorry_detail?.calling_disposition} allowCsUser>
                        <CallingDispositionView
                            callingDisposition={data?.lorry_detail?.calling_disposition}
                            limit={35}
                        />
                    </IfCanAccess>
                </IfCanAccess>
            </div>

            <div className={Styles.footerContent}>
                <VerticalFlex alignItems="flex-start" justifyContent="center" gap={10}>
                    {!!company_detail?.id && (
                        <UserProfileInfo
                            id={id}
                            companyId={company_detail?.id}
                            companyLogo={company_detail?.logo}
                            name={company_detail?.name}
                            isBankVerified={company_detail?.is_bank_verified}
                            isVerified={company_detail?.is_company_verified}
                            isAadhaarVerified={company_detail?.is_aadhaar_verified}
                            isGstVerified={company_detail?.is_gst_verified}
                            isPanVerified={company_detail?.is_company_verified}
                            isMember={company_detail?.is_member}
                            rating={company_detail?.ratings}
                            serviceType={company_detail?.service_type}
                            handleClickProfile={handleClickProfile}
                            phoneNumber={data?.company_detail?.phone_number}
                            agentTagged={!!agent_detail}
                        />
                    )}
                </VerticalFlex>

                <IfCanAccess action={""} allowCsUser={checkPermission(ADMIN_OPERATION.profileView)} allowCustomer>
                    <div className={Styles.bidBtn}>
                        <Button
                            isRounded
                            width={100}
                            onClick={() => {
                                onBidNowClick?.();
                                trackOnClickBidNow?.();
                            }}
                            id={`${id}--bidNow`}
                        >
                            <Typography size="xs">Bid Now</Typography>
                        </Button>
                    </div>
                </IfCanAccess>
            </div>
        </div>
    );
};

export default MarketplaceLorryCard;
